@import "variables";
html { height: 100%; }
@font-face {
  font-family:"quimby-gubernatorial";
  src:url("https://use.typekit.net/af/63b37e/00000000000000003b9acfef/27/l?primer=8fa4b058e82492e04d7cdf52b0e62c529469bc2a019e2b65c17d5e267ca91b8d&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/63b37e/00000000000000003b9acfef/27/d?primer=8fa4b058e82492e04d7cdf52b0e62c529469bc2a019e2b65c17d5e267ca91b8d&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/63b37e/00000000000000003b9acfef/27/a?primer=8fa4b058e82492e04d7cdf52b0e62c529469bc2a019e2b65c17d5e267ca91b8d&fvd=n4&v=3") format("opentype");
  font-style:normal;font-weight:400;
}
@font-face {
  font-family: "Interstate Bd Cond";
  font-display: Auto;
  font-weight: Normal;
  font-style: Normal;
  src: url("https://cloud.typenetwork.com/projectLicenseWeb/12506/fontfile/eot/?b1b0007adddac0657fdb574a3dc57801a5d32773");
  src: url("https://cloud.typenetwork.com/projectLicenseWeb/12506/fontfile/eot/?b1b0007adddac0657fdb574a3dc57801a5d32773#iefix") format("embedded-opentype"),
  url("https://cloud.typenetwork.com/projectLicenseWeb/12506/fontfile/woff2/?b1b0007adddac0657fdb574a3dc57801a5d32773") format("woff2"),
  url("https://cloud.typenetwork.com/projectLicenseWeb/12506/fontfile/woff/?b1b0007adddac0657fdb574a3dc57801a5d32773") format("woff");
}

@font-face {
  font-family: "Interstate Reg Cond";
  font-display: Auto;
  font-weight: Normal;
  font-style: Normal;

  src: url("https://cloud.typenetwork.com/projectLicenseWeb/12505/fontfile/eot/?b1b0007adddac0657fdb574a3dc57801a5d32773");
  src: url("https://cloud.typenetwork.com/projectLicenseWeb/12505/fontfile/eot/?b1b0007adddac0657fdb574a3dc57801a5d32773#iefix") format("embedded-opentype"),
  url("https://cloud.typenetwork.com/projectLicenseWeb/12505/fontfile/woff2/?b1b0007adddac0657fdb574a3dc57801a5d32773") format("woff2"),
  url("https://cloud.typenetwork.com/projectLicenseWeb/12505/fontfile/woff/?b1b0007adddac0657fdb574a3dc57801a5d32773") format("woff");

}

@font-face {
  font-family:"athelas";
  src:url("https://use.typekit.net/af/27d1c7/00000000000000000000d837/27/l?primer=8fa4b058e82492e04d7cdf52b0e62c529469bc2a019e2b65c17d5e267ca91b8d&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/27d1c7/00000000000000000000d837/27/d?primer=8fa4b058e82492e04d7cdf52b0e62c529469bc2a019e2b65c17d5e267ca91b8d&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/27d1c7/00000000000000000000d837/27/a?primer=8fa4b058e82492e04d7cdf52b0e62c529469bc2a019e2b65c17d5e267ca91b8d&fvd=i7&v=3") format("opentype");
  font-style:italic;font-weight:700;
}



body {
  //background-image: url("../images/Side_Tree_Title.png");
  background-repeat: no-repeat;
  background-position: right top;
  height: 100%;
  width: 100%;
  background-size: cover;
}
.wrapper {
  width: 90%;
  margin: 0 auto;
  padding-top: 9vh;
}

input:focus{
  outline: none;
}

button{
  &:focus{
    outline: none!important;
    color: $white;
    box-shadow: none!important;
  }
  &:hover {
    color: $white;
  }
  &:disabled{
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.text-center {
  text-align: center;
}
.primary-btn {
  background: $secondary_color;
  color: $white;
  border: none;
  border-radius: 3px;
  padding: 10px 20px;
  &:disabled{
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.v-center {
  width: 90%;
  margin: 0 auto;
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.start_page {
  h2.heading{
    font-size: 11vh;
    font-family: "athelas";
    color: $secondary_color;
    text-align: center;
    line-height: 10vh;
    text-transform: uppercase;
  }
  span.sub-heading {
    font-size: 4.375vh;;
    margin-top: 4vh;
    margin-bottom: 4vh;
    display: block;
    line-height: 8vh;
    text-align: center;
    font-family: Arial;
    font-weight: 600;
    color: #000000;
    text-transform: uppercase;
  }
  .btn{
    font-size: 4.375vh;
  }
  .logo-container {
    width: 100%;
    margin-bottom: 20px;
    display: inline-block;
    @media only screen
    and (orientation : landscape) {
      width: 50%;
    }
  }

}

.btn { 
  font-size: 4.375vh;
  color: $white;
  margin-top: 1vh;
  display: inline-block;
  cursor: pointer;
  background-size: cover;
  font-family: arial;
  white-space: nowrap;
  text-transform:capitalize;
  padding: 0 7vh;
  background:#000000;
  border-radius:5px;
  &:hover{
    color: $white;
  }
}

.player_details {
  h2 {
    font-size: 4.375vh;
    margin-bottom: 2%;
    color: #000000;
    font-family: arial;
    font-weight:600;
  }
  form {
    ul.registration {
      padding: 0;
      label {
        color: #000000;
        font-family: arial;
        font-size: 3.125vh;
        margin-top:1vh;
      }
      li {
        list-style: none;
        input {
          
          border: none;
          width: 100%;
          background-size: 100%;
          height: 8.3vh;
          padding: 0 3vh;
          font-size: 2.5vh;
          border-radius: 5px;
          margin-bottom: 1vh;
          font-family: arial;

          @media only screen
          and (orientation : portrait) {
            position: relative;
            top: -10px;
          }
        }
        .dob-container{
          overflow: hidden;
          display:flex;
          .dop-input{
            width: 100%;
            margin-right: 20px;
            > input {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

// .player_details form ul.registration li .dob-container .dop-input:last-child {
//   margin-right: 0px;
// }

.dob-container .dob-input.dob-year{
  margin-right:0!important;
}

.difficulties {
  h2 {
    font-size: 4.375vh;
    margin-bottom: 10vh;
    color: #000000;
    font-family: arial;
    font-weight: 600;
  }
  ul {
    display: flex;
    margin-bottom: 6vh;
    padding: 0;
    li {
      list-style: none;     
      width: 30%;
      margin-right: 3%;
      font-size: 3vh;
      text-align: center;      
      color: #fff;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        background-size: cover;
        box-shadow: 0 3px 5px hsla(0, 0%, 0%, 0.44);
      }
      &.active{
        background-size: cover;
        box-shadow: 0 3px 5px hsla(0, 0%, 0%, 0.44);
      }
    }
  }
  p {
    font-family: arial;
    font-size: 2.8125vh;
    line-height: 4vh;
    color: #000000;
    text-align: center;
    margin-bottom: 4vh;
  }
}

.questions {
  padding-top: 6vh;
  ul {
    width: 60%;
    padding: 0;
    li {
      list-style: none;
      position: relative;
      .correct-ans {
        display: none;
        background: url("../images/correct_ans.svg") no-repeat center top;
        width: 240px;
        height: 127px;
        position: absolute;
        top: 0;
        right: -249px;
      }
      .oops{
        font-family: "Interstate Reg Cond";
        font-weight: bold;
        font-size: 46px;
        position: absolute;
        right: -230px;
        letter-spacing: 2px;
        top: -16px;
        color: #000000;
        display: none;
      }
      .alpha {
        display: inline-block;
        margin-right: 15px;
        text-transform: capitalize;
      }
      .radio{
        opacity: 0;
        position: absolute;
      }
      .button-style {
        width: 100%;
        height: 100%;
        padding: 20px 45px;
        border: none;
        background: #ffffff;
        background-size: cover;
        font-size: 2.8125vh;
        margin-bottom: 2vh;
        font-family: arial;
        display: flex;
        border-radius: 5px;
        color: #000000;
        position: relative;
        &:hover {
          background-color: #ffffff!important;
          background-size: cover;
          box-shadow: 0 3px 5px hsla(0, 0%, 0%, 0.14);
        }
      }
      &.green{
        .correct-ans {
          display: block;
        }
      }
      .radio:checked + button.button-style{
        background-color: #ffffff!important;
          background-size: cover;
          box-shadow: 0 3px 5px hsla(0, 0%, 0%, 0.14);
      }
      &.right {
        &:before {
          content: 'CORRECT ANSWER!';
          position: absolute;
          right: -195px;
          top: 16px;
          width: 50px;
          color: #00A351;
          font-size: 30px;
          line-height: 26px;
          font-weight: bold;
          letter-spacing: 1px;
          padding-left: 10px;
        }
        &:after {
          content: '';
        //  background: url("../images/right.svg") no-repeat left top;
          display: inline-block;
          width: 140px;
          height: 140px;
          position: absolute;
          right: -146px;
          top: -19px;
        }
      }
      &.wrong {
        .oops{
          display: block;
        }
        &:before {
          content: 'WRONG ANSWER!';
          position: absolute;
          right: -309px;
          top: 40px;
          color: #000000;
          font-size: 26px;
          line-height: 26px;
          font-weight: bold;
        }
        &:after {
          content: '';
          background: url("../images/wrong.svg") no-repeat left top;
          color: $secondary_color;
          display: inline-block;
          width: 130px;
          height: 130px;
          position: absolute;
          right: -106px;
          top: -17px;
        }
      }
    }
  }
  p {
    font-size: 4.375vh;
    width: 90%;
    margin-bottom: 4%;
    font-family: arial;
    color: #000000;
    font-weight: 600;
  }
}

.scores {
  padding-top: 3vh;
  text-align: center;
  h3 {
    font-size: 4.375vh;
    color: #000000;
    text-align: center;
    font-family: arial;
    font-weight: 600;
  }
  .score_board {
    color: red;
    font-size: 4.375vh;
    text-align: center;
    display: block;
    font-family: arial;
    font-weight: 600;
  }
  .share-email{
    text-align: left;
    input {
      border: none;
      height: 5vh;
      width: 100%;
      padding: 0 3vh;
      font-size: 2.5vh;
      background: #ffffff;
      border-radius: 5px;
      font-family: arial;
      color: #000000;
    }
    .share-email-btn{
      margin-top: 0;
      padding: 0 3vh;
      font-size: 3.2vh;
    }
  }
  .social_media {
    display: block;
    text-align: center;
    span {
      display: inline-block;
      margin-right: 5%;
      width: 7vh;
      height: 7vh;
    }
    .facebook {
      background: url("../images/asset_16ldpi.svg") no-repeat left top;
    }
    .twitter {
      background: url("../images/asset_15ldpi.svg") no-repeat left top;
    }
  }
  p {
    font-size: 3.125vh;
    text-align: center;
    font-family: arial!important;
    color:#000000;
  }
  h2 {
    font-size: 4vh;
    color: $secondary_color;
    font-family: "Interstate Bd Cond";
    margin: 2vh 0;
  }

}


/* location setup */

.location-setup {
  width: 60%;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 300px;
  .location-select {
    width: 100%;
    height: 50px;
    border: solid 1px $light_gray;
  }
  .send-location{
    margin-top: 20px;
  }
}

.email-error{
  display: block;
  color: red;
  min-height: 35px;
  font-size: 1.6vh;
}

.dob-error{
  display: block;
  color: $negetive;
  font-size: 2vh;
}

.no-style {
  background: #ffffff;
    color: #fff;
    border: none;
    width: 100%;
    height: 100%;
    padding: 12px 0;
    color: #000000!important;
    font-size: 2.8125vh;
    border-radius: 5px;
    font-family: arial;
}

.loader-container{
  text-align: center;
}

.item-enter {
  transform: translate(200%);
}
.item-enter-active {
  transform: translate(0%);
  transition: transform 500ms ease-in-out;
}
.item-exit {
  transform: translate(0%);
}
.item-exit-active {
  transform: translate(-100%);
  transition: transform 400ms ease-in-out;
}
.question-list--container {
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 75vh;
}
.question-view{
  position: absolute;
  width: 100%;
  .question-img{
    width: 500px;
    height: 118px;
    display: inline-block;
  }
}

.overlay-loader {
  position: absolute;
  display: none;
  background: rgba(255, 255, 255, 0.7);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  justify-content: center;
  align-items: center;
}

.show-loader {
  .overlay-loader {
    display: flex;
  }
}

.leaderboard-container{
  padding-top: 0;
  h2 {
    font-size: 4.375vh;
    color: #000000;
    font-family: arial;
    margin: 2vh 0;
    text-align: center;
    font-weight: 600;
  }
  .leaderboard {
    padding: 0;
    width: 100%;
    li {
      list-style: none;
      width: 100%;
      padding-left: 5%;
      padding-right: 5%;
      margin-bottom: 1vh;
      font-family: arial;
      color: #000000;
      font-size: 2.5vh;
      background: #ffffff;
      border-radius: 5px;
      text-transform: capitalize;
      font-family:arial;
      span {
        &:last-child {
          float: right;
        }
      }
    }
  }
}
.email-send{
  font-size: 3vh;
  text-align: center;
  color: #000000;
  font-family: "Interstate Bd Cond";
}

.modal-container {
  display: none;
  &.show{
    display: block;
  }
}

.modal-overlay {
  background: rgba(0,0,0, 0.7);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0
}

.modal-popup{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  margin: 20px;
  border: solid 1px #ccc;
  border-radius: 4px;
  // background-image: url("../images/Side_Tree_Title.png");
  background-repeat: no-repeat;
  background-position: right top;
  background-size: cover;
}


button.button-style:before {
  position: absolute;
  content: '';
  width: 15px;
  height: 15px;
  background: #ff000000;
  right: 20px;
  top: 25px;
  border-radius: 50%;
  border: 1px solid transparent;
  outline: 1px solid #000000;
}
.questions ul li .radio:checked + button.button-style:before {
  background: #000000;
  margin: 2px;
  box-sizing: border-box;
  border: 2px solid #f8f9fa;
  outline: 1px solid #000000;
}






